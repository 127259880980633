/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useGetVacationsQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import NewVacationButton from './VacationsComponents/VacationsCardButton'
import VacationsCard from './VacationsComponents/VacationsCard'

/* Styled components -------------------------------------------------------- */
interface SpaceBetweenContainerProps {
  last?: boolean;
}

const SpaceBetweenContainer = styled.div<SpaceBetweenContainerProps>`
  padding-bottom: ${(props) => props.last ? '0px' : '20px'};
`

/* Component declaration ---------------------------------------------------- */
interface VacationPageProps {}

const VacationPage: React.FC<VacationPageProps> = () => {

  const {
    currentData: vacationList = [],
    isFetching: isFetchingVacationList,
  } = useGetVacationsQuery()

  return (
    <div>
      <LargeTitle>
        Congés
        <NewVacationButton />
      </LargeTitle>
      {
        isFetchingVacationList ?
          <CircularProgress /> :
          vacationList.map((vac, index, array) => (
            <SpaceBetweenContainer
              last={index === array.length}
              key={vac.id}
            >
              <VacationsCard
                vacation={vac}
              />
            </SpaceBetweenContainer>
          ))
      }
      <Footer />
    </div>
  )
}

export default VacationPage
