/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetArticleBordereauListQuery,
  useGetDiverseBordereauListQuery,
  useGetMeasureUnitListQuery,
  useGetFranchiseQuery,
  useGetLineTypeListQuery,
  useGetRSEQuery,
  useGetRemiseQuery,
  useGetTVARateListQuery,
  useLazyGetQuoteDraftQuery,
  usePostCalculQuoteEndMutation,
  usePostImportQuoteMutation,
  usePostSaveQuoteDraftMutation,
  usePostSaveQuoteMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { formatApiErrorMessage } from 'helpers/formatApiErrorMessage'
import { parseNumber } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import RouteTitle from 'router/RouteTitle'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
// import RateField from 'components/FieldWithInputAdornment/RateField'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import BackButton from 'components/BackButton/BackButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import QuoteReadOnlyLines from './QuoteComponents/QuoteReadOnlyLines'
import QuoteLines from './QuoteComponents/QuoteLines'
import QuoteTotal from './QuoteComponents/QuoteTotal'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { QuoteLineRequest } from 'types/QuoteInvoice'
import type { Devis } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface QuoteRequest extends Devis {
  lignesDevis: QuoteLineRequest[] | null;
}

const quoteSchema = Yup.object().shape<Shape<QuoteRequest>>({
  ordre: Yup.string().required('Le numéro de devis est obligatoire'),
  deductionFranchise: Yup.boolean().required(),
  rse: Yup.boolean().required(),
  remise: Yup.boolean().required(),
  sequence: Yup.string(),
  lignesDevis: Yup.array(Yup.object().shape<Shape<QuoteLineRequest>>({
    disabled: Yup.boolean(),
    type: Yup.string().required('Le type est obligatoire'),
    unite: Yup.string(),
    codeArticle: Yup.string(),
    libelle: Yup.string(),
    quantite: Yup.number().when('type', {
      is: 'A',
      then: (schema) => schema.min(0.01, 'La quantité doit être supérieure à 0.01'),
    }),
    tva: Yup.string().nullable().when('type', {
      is: 'A',
      then: (schema) => schema.required('La tva est obligatoire'),
    }),
  })),
}).required()

export type QuoteForm = FormikContextType<QuoteRequest>

/* Styled components -------------------------------------------------------- */
const TitleButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

const FirstLine = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 10px;
`

// const SecondLine = styled.div`
//   display: grid;
//   grid-template-columns: 2fr 1fr 2fr 1fr;
//   gap: 10px;
// `

const AddButtonContainer = styled(Button)`
  min-width: 200px;
  float: right;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-bottom: 25px;
`

const Declined = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface QuotePageProps {
  edit?: boolean;
  readOnly?: boolean;
}

const QuotePage: React.FC<QuotePageProps> = ({
  edit = false,
  readOnly = false,
}) => {
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ counter, setCounter ] = useState<number>(1)

  const defaultLine: QuoteLineRequest = {
    disabled: false,
    libelle: '',
    codeArticle: '',
    prixHT: 0,
    prixTTC: 0,
    prixUnitaire: 0,
    quantite: 0,
    tva: null,
    type: 'A',
    unite: '1',
  }

  const {
    currentData: lineTypeList = [],
    isFetching: isFetchingLineTypeList,
  } = useGetLineTypeListQuery()
  const {
    currentData: measureUnitList = [],
    isFetching: isFetchingMeasureUnitList,
  } = useGetMeasureUnitListQuery()
  const {
    currentData: tvaRateList = [],
    isFetching: isFetchingTVARateList,
  } = useGetTVARateListQuery()
  const {
    currentData: franchise = 0,
    isFetching: isFetchingFranchise,
  } = useGetFranchiseQuery(caseId)
  const {
    currentData: rse = 0,
    isFetching: isFetchingRse,
  } = useGetRSEQuery(caseId)
  const {
    currentData: remise = 0,
    isFetching: isFetchingRemise,
  } = useGetRemiseQuery(caseId)
  const {
    currentData: articleBordereauList = [],
    isFetching: isFetchingArticleBordereauList,
  } = useGetArticleBordereauListQuery({ dossier: caseId })
  const {
    currentData: diverseBordereauList = [],
    isFetching: isFetchingDiverseBordereauList,
  } = useGetDiverseBordereauListQuery()
  const [
    getDraft,
  ] = useLazyGetQuoteDraftQuery()
  const [
    submitImportQuote,
    { isLoading: isFetchingImportQuote },
  ] = usePostImportQuoteMutation()
  const [
    submitSaveDraft,
    { isLoading: isSavingDraft },
  ] = usePostSaveQuoteDraftMutation()
  const [
    submitSaveQuote,
    { isLoading: isSavingQuote },
  ] = usePostSaveQuoteMutation()
  const [
    submitQuoteCalculs,
    { data: calculs },
  ] = usePostCalculQuoteEndMutation()

  const formikForm: QuoteForm = useForm<QuoteRequest>(
    {
      initialValues: {
        sequence: 0,
        commentaire: '',
        deductionFranchise: false,
        montantFranchise: 0,
        tauxRemise: 0,
        remise: false,
        rse: false,
        tauxRSE: 0,
        lignesDevis: [ { ...defaultLine, type: 'TI' }, defaultLine, { ...defaultLine, type: 'TTI' } ],
        ordre: '',
      },
      validationSchema: quoteSchema,
    },
  )

  useEffect(() => {
    if (!edit && !readOnly) return

    getDraft(caseId).then((data) => {
      if (data.data) {
        formikForm.setValues({
          ...formikForm.values,
          ...data.data,
          lignesDevis: data.data.lignesDevis?.map((value) => value.codeArticle ? ({ ...value, disabled: true }) : value) || [],
        })
        setCounter(counter + 1)
      }
    }).catch(console.error)
  }, [ edit, readOnly ])

  useEffect(() => {
    if (!isFetchingFranchise && !isFetchingRse && !isFetchingRemise) {
      formikForm.setFieldValue('montantFranchise', franchise)
      formikForm.setFieldValue('tauxRSE', rse)
      formikForm.setFieldValue('tauxRemise', remise)
    }
  }, [ isFetchingFranchise, isFetchingRse, isFetchingRemise ])

  useEffect(() => {
    const lignesDevis = structuredClone(formikForm.values.lignesDevis) || []

    lignesDevis.forEach((line, index) => {
      const result: QuoteLineRequest = { ...structuredClone(line), prixHT: 0, prixTTC: 0 }
      let i = index
      if (!result.prixHT) {
        result.prixHT = 0
      }
      if (!result.prixTTC) {
        result.prixTTC = 0
      }

      if (line.type === 'TST') {
        for (i; lignesDevis[i].type !== 'ST' && i > 0; i--) {
          const newLine = lignesDevis[i]

          if (newLine.type === 'A') {
            result.prixHT += parseNumber(newLine.prixHT)
            result.prixTTC += parseNumber(newLine.prixTTC)
          }
        }
        if (lignesDevis[i].type === 'ST') {
          result.libelle = `Sous total - ${lignesDevis[i].libelle}`
          lignesDevis[index] = result
        }
      }
      if (line.type === 'TTI') {
        for (i; lignesDevis[i].type !== 'TI' && i > 0; i--) {
          const newLine = lignesDevis[i]

          if (newLine.type === 'A') {
            result.prixHT += parseNumber(newLine.prixHT)
            result.prixTTC += parseNumber(newLine.prixTTC)
          }
        }
        if (lignesDevis[i].type === 'TI') {
          result.libelle = `Total - ${lignesDevis[i].libelle}`
          lignesDevis[index] = result
        }
      }
      if (line.type === 'A' && !line.tva) {
        const tva = lignesDevis.find((l) => l.type === 'A' && l.tva)?.tva ?? null
        lignesDevis[index].tva = tva
      }
    })

    submitQuoteCalculs({ caseId, data: { ...formikForm.values, lignesDevis: lignesDevis?.filter((line) => line.type === 'A') }})

    formikForm.setFieldValue('lignesDevis', lignesDevis)
  }, [ counter ])

  const updateCounter = () => {
    setCounter(counter + 1)
  }

  const addNewLineOrSection = (section: boolean = false) => {
    const lines = structuredClone(formikForm.values.lignesDevis || [])
    const tva = lines.find((line) => line.type === 'A' && line.tva)?.tva ?? null
    let newLines = lines

    if (section) {
      newLines = [
        ...lines,
        { ...defaultLine, type: 'TI' },
        { ...defaultLine, tva },
        { ...defaultLine, type: 'TTI' },
      ]
    } else {
      newLines = [
        ...lines,
        { ...defaultLine, tva },
      ]
    }

    formikForm.setFieldValue('lignesDevis', newLines)
    updateCounter()
  }

  const onSaveClick = () => {
    submitSaveDraft({ caseId, data: formikForm.values }).then((response) => {
      if (isApiError(response)) {
        toast.error(`Une erreur est survenue lors de l'enregistrement du brouillon : ${formatApiErrorMessage(response.error)}.`)
      } else {
        toast.success('Le brouillon du devis a bien été enregistré.')
        navigate(`/dossiers/${caseId}/devis`)
      }
    }).catch(console.error)
  }

  const onSendClick = () => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) {
          return submitSaveQuote({ caseId, data: formikForm.values })
        } else {
          console.log('Quote errors', errors)
        }
      })
      .then((response) => {
        if (isApiError(response)) {
          toast.error(`Une erreur est survenue lors de l'envoi : ${formatApiErrorMessage(response.error)}.`)
        } else if (response) {
          toast.success('Le devis a bien été envoyé.')
          navigate(`/dossiers/${caseId}`)
        }
      })
      .catch(console.error)
      .finally(() => formikForm.setSubmitting(false))
  }

  const onUpdateSegmentedButtons = (fieldName: 'deductionFranchise' | 'rse' | 'remise', value: boolean) => {
    formikForm.setFieldValue(fieldName, value)
    updateCounter()
  }

  const handleImportPDF = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      submitImportQuote({
        caseId,
        data: { Fichier: Object.values(e.target.files ?? {})[0], Nom: e.target.files[0].name },
      }).catch(console.error)
    }
  }

  // const handleImportInfos = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   if (e.target.files?.length !== undefined && e.target.files.length > 0) {
  //     submitImportQuote({
  //       caseId,
  //       data: { Fichier: Object.values(e.target.files ?? {})[0], Nom: e.target.files[0].name },
  //     }).then((data) => {
  //       if ('data' in data) {
  //         formikForm.setValues({ ...formikForm.values, ...data.data })
  //       }
  //     }).catch(console.error)
  //   }
  // }

  const booleanOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  const isLoading = useMemo(() => isFetchingLineTypeList || isFetchingMeasureUnitList || isFetchingTVARateList || isFetchingFranchise || isFetchingRemise || isFetchingRse || isFetchingImportQuote || isFetchingArticleBordereauList || isFetchingDiverseBordereauList, [
    isFetchingLineTypeList,
    isFetchingMeasureUnitList,
    isFetchingTVARateList,
    isFetchingFranchise,
    isFetchingRemise,
    isFetchingRse,
    isFetchingImportQuote,
    isFetchingArticleBordereauList,
    isFetchingDiverseBordereauList,
  ])

  return (
    <Form form={formikForm}>
      <RouteTitle title={`Devis ${caseId}`} />
      <BackButton onClick={() => navigate(`/dossiers/${caseId}`)}>
        Retourner au suvi
      </BackButton>
      <LargeTitle>
        <Declined>
          {(edit || readOnly) ? 'Devis' : 'Nouveau Devis'}
          {
            formikForm.values.motifRefusDevis ?
              <ColoredSquareChip color="red">
                {formikForm.values.motifRefusDevis}
              </ColoredSquareChip> :
              <div />
          }
        </Declined>
        {
          !readOnly &&
            <TitleButtonContainer>
              <AttachmentButton
                onChange={handleImportPDF}
                multiple={false}
                name="quote-pdf-attachment"
              >
                <Button
                  variant="outlined"
                  component="span"
                  disabled={isFetchingImportQuote}
                >
                  Lier un devis
                </Button>
              </AttachmentButton>
              <Button
                variant="outlined"
                onClick={onSaveClick}
                disabled={isSavingDraft}
              >
                Enregistrer
              </Button>
              <Button
                variant="contained"
                onClick={onSendClick}
                disabled={isSavingQuote}
              >
                Envoyer
              </Button>
            </TitleButtonContainer>
        }
      </LargeTitle>
      {
        isLoading ?
          <CircularProgress /> :
          <React.Fragment>
            <Card>
              <CardContent>
                <FirstLine>
                  <div>
                    <FormBoldTitle>
                      Votre numéro de devis interne
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      name="ordre"
                      placeholder="Numéro devis interne"
                      size="small"
                      disabled={readOnly}
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Déduction de la franchise
                    </FormBoldTitle>
                    <SegmentedButtons
                      options={booleanOptions}
                      setSelectedOption={(option) => onUpdateSegmentedButtons('deductionFranchise', option)}
                      selectedOption={formikForm.values.deductionFranchise}
                      smaller
                      disabled
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Montant franchise
                    </FormBoldTitle>
                    <PriceField
                      name="montantFranchise"
                      size="small"
                      disabled
                    />
                  </div>
                </FirstLine>
                {/* <SecondLine>
                  <div>
                    <FormBoldTitle>
                      RSE
                    </FormBoldTitle>
                    <SegmentedButtons
                      options={booleanOptions}
                      setSelectedOption={(option) => onUpdateSegmentedButtons('rse', option)}
                      selectedOption={formikForm.values.rse}
                      smaller
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Taux RSE
                    </FormBoldTitle>
                    <RateField
                      name="tauxRSE"
                      size="small"
                      disabled={!formikForm.values.rse}
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Remise
                    </FormBoldTitle>
                    <SegmentedButtons
                      options={booleanOptions}
                      setSelectedOption={(option) => onUpdateSegmentedButtons('remise', option)}
                      selectedOption={formikForm.values.remise}
                      smaller
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Taux remise
                    </FormBoldTitle>
                    <RateField
                      name="tauxRemise"
                      size="small"
                      disabled={!formikForm.values.remise}
                    />
                  </div>
                </SecondLine> */}
                <div>
                  <FormBoldTitle>
                    Commentaire
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    name="commentaire"
                    placeholder="Commentaire"
                    size="small"
                    disabled={readOnly}
                  />
                </div>
              </CardContent>
            </Card>
            {
              readOnly ?
                <QuoteReadOnlyLines
                  lines={formikForm.values.lignesDevis || []}
                  lineTypeList={lineTypeList}
                  measureUnitList={measureUnitList}
                  tvaRateList={tvaRateList}
                /> :
                <QuoteLines
                  formikForm={formikForm}
                  measureUnitList={measureUnitList}
                  lineTypeList={lineTypeList}
                  tvaRateList={tvaRateList}
                  updateCounter={updateCounter}
                  articleBorderauList={articleBordereauList}
                  diverseBorderauList={diverseBordereauList}
                />
            }
            {
              !readOnly &&
                <ButtonContainer>
                  <AddButtonContainer
                    variant="contained"
                    onClick={() => addNewLineOrSection(true)}
                  >
                    Ajouter une section
                  </AddButtonContainer>
                  <AddButtonContainer
                    variant="contained"
                    onClick={() => addNewLineOrSection(false)}
                  >
                    Ajouter une ligne
                  </AddButtonContainer>
                </ButtonContainer>
            }
            <QuoteTotal calculs={calculs} />
          </React.Fragment>
      }
      <Footer />
    </Form>
  )
}

export default QuotePage
