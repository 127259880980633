/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useDeleteDocumentsMutation,
  useDeleteMediaFilesMutation,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  ButtonGroup,
} from '@mui/material'
import {
  Delete,
  InsertDriveFileOutlined,
  InsertPhotoOutlined,
} from '@mui/icons-material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Styled components -------------------------------------------------------- */
const DownloadButton = styled(CaseWorkflowStyledComponents.Button)`
  padding: 5px 6px 5px 3px;
  justify-content: left;
  width: fit-content;
  height: 43px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    margin-right: 2px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

const DeleteButton = styled(Button)`
  width: 30px;
  min-width: 30px !important;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowDownloadButtonProps {
  id: string;
  url?: string;
  name: string;
  isPhoto?: boolean;
  isOtherMedia?: boolean;
  disable?: boolean;
}

const CaseWorkflowDownloadButton: React.FC<CaseWorkflowDownloadButtonProps> = ({
  id,
  url,
  name,
  isPhoto = false,
  isOtherMedia = false,
  disable = false,
}) => {
  const [
    submitDeleteDocument,
    { isLoading: isDeletingFile },
  ] = useDeleteDocumentsMutation()
  const [
    submitDeleteMedia,
    { isLoading: isDeletingMedia },
  ] = useDeleteMediaFilesMutation()

  const onClick = () => {
    window.open(url || '', '_blank', 'noreferrer')
  }

  const onDeleteClick = () => {
    if (isPhoto || isOtherMedia) {
      submitDeleteMedia(id).catch(console.error)
    } else {
      submitDeleteDocument(id).catch(console.error)
    }
  }

  return (
    <ButtonGroup variant="outlined">
      <DownloadButton
        onClick={onClick}
        disabled={!isValidString(url)}
      >
        {
          isPhoto ?
            <InsertPhotoOutlined /> :
            <InsertDriveFileOutlined />
        }
        {name}
      </DownloadButton>
      {
        !disable &&
          <DeleteButton
            onClick={onDeleteClick}
            disabled={isDeletingFile || isDeletingMedia}
          >
            <Delete />
          </DeleteButton>
      }
    </ButtonGroup>

  )
}

export default CaseWorkflowDownloadButton
