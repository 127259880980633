/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type {
  CodeLabel,
  LigneDevis,
  TauxTVA,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface GridContainerProps {
  important?: boolean;
  line?: boolean;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: 120px 50px 2fr 1fr 0.5fr 1fr 1fr 0.5fr 1fr;
  gap: 25px;
  align-items: center;
  font-weight: ${(props) => props.important ? 'bold' : 'normal'};
  text-transform: ${(props) => props.important ? 'uppercase' : 'none'};
  border-bottom: ${(props) => props.line ? `2px solid ${props.theme.colors.grey}` : ''};
  margin: 5px 0px;
`

const CardLineContainer = styled(Card)`
  margin: 20px 0px;
  padding: 3px 10px 0px;
`

const PriceContainer = styled.div`
  justify-self: right;
`

/* Component declaration ---------------------------------------------------- */
interface QuoteReadOnlyLinesProps {
  lines: LigneDevis[];
  lineTypeList: CodeLabel[];
  measureUnitList: CodeLabel[];
  tvaRateList: TauxTVA[];
}

const QuoteReadOnlyLines: React.FC<QuoteReadOnlyLinesProps> = ({
  lines,
  lineTypeList,
  measureUnitList,
  tvaRateList,
}) => {
  return (
    <CardLineContainer>
      <GridContainer important>
        <div>
          Type
        </div>
        <div>
          Code
        </div>
        <div>
          Article
        </div>
        <div>
          Quantité
        </div>
        <div>
          Unité
        </div>
        <div>
          P.U
        </div>
        <div>
          Prix HT
        </div>
        <div>
          TVA
        </div>
        <div>
          Prix TTC
        </div>
      </GridContainer>
      {
        lines.map((item, index) => (
          <GridContainer
            key={index}
            line={index !== lines.length - 1}
          >
            <div>
              {lineTypeList.find((l) => l.code === item.type)?.libelle || item.type}
            </div>
            <div>
              {item.codeArticle}
            </div>
            <div>
              {item.libelle}
            </div>
            <div>
              {item.quantite}
            </div>
            <div>
              {measureUnitList.find((l) => l.code === item.unite)?.libelle || item.unite}
            </div>
            <PriceContainer>
              {`${item.prixUnitaire} €`}
            </PriceContainer>
            <PriceContainer>
              {`${item.prixHT} €`}
            </PriceContainer>
            <div>
              {tvaRateList.find((l) => l.tva.code === item.tva)?.tva.libelle || item.tva}
            </div>
            <PriceContainer>
              {`${item.prixTTC} €`}
            </PriceContainer>
          </GridContainer>
        ))
      }
    </CardLineContainer>
  )
}

export default QuoteReadOnlyLines
