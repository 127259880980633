/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import { DragDropContext } from 'react-beautiful-dnd'
import StrictModeDroppable from 'components/StrictModeDroppable/StrictModeDroppable'
import InvoiceLine from './InvoiceLine'

/* Type imports ------------------------------------------------------------- */
import type { DropResult } from 'react-beautiful-dnd'
import type { InvoiceForm } from '../InvoicePage'
import type {
  Bordereau,
  CodeLabel,
  LigneFacture,
  TauxTVA,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 120px 90px 2fr repeat(6, 1fr) 20px;
  gap: 5px;
  padding: 10px 10px 10px 0px;
  align-items: center;
`

const CardLineContainer = styled(Card)`
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
`

const Container = styled.div`
  margin: 20px 0px;
`

/* Component declaration ---------------------------------------------------- */
interface InvoiceLinesProps {
  formikForm: InvoiceForm;
  tvaRateList: TauxTVA[];
  lineTypeList: CodeLabel[];
  measureUnitList: CodeLabel[];
  updateCounter: () => void;
  articleBorderauList: Bordereau[];
  diverseBorderauList: Bordereau[];
}

const InvoiceLines: React.FC<InvoiceLinesProps> = ({
  formikForm,
  tvaRateList,
  lineTypeList,
  measureUnitList,
  updateCounter,
  articleBorderauList,
  diverseBorderauList,
}) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const reorder = (list: LigneFacture[], startIndex: number, endIndex: number) => {
      const result = [ ...list ]
      const [ removed ] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    const items = reorder(
      formikForm.values.lignesFacture || [],
      result.source.index,
      result.destination.index,
    )

    formikForm.setFieldValue('lignesFacture', items)
    updateCounter()
  }

  return (
    <Container>
      <CardLineContainer>
        <GridContainer>
          <div />
          <div>
            Type
          </div>
          <div>
            Code
          </div>
          <div>
            Article
          </div>
          <div>
            Quantité
          </div>
          <div>
            Unité
          </div>
          <div>
            P.U
          </div>
          <div>
            Prix HT
          </div>
          <div>
            TVA
          </div>
          <div>
            Prix TTC
          </div>
        </GridContainer>
      </CardLineContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable">
          {
            (provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  formikForm.values.lignesFacture?.map((item, index) => (
                    <InvoiceLine
                      key={`field-${index}`}
                      index={index}
                      formikForm={formikForm}
                      measureUnitList={measureUnitList}
                      lineTypeList={lineTypeList}
                      tvaRateList={tvaRateList}
                      updateCounter={updateCounter}
                      articleBorderauList={articleBorderauList}
                      diverseBorderauList={diverseBorderauList}
                    />
                  ))
                }
                {provided.placeholder}
              </div>
            )
          }
        </StrictModeDroppable>
      </DragDropContext>
    </Container>
  )
}

export default InvoiceLines
