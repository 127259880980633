/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useIsReadOnly } from 'store/hooks'
import DateUtils from 'helpers/DateUtils'
import { useDeleteVacationMutation } from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import VacationsCardButton from './VacationsCardButton'

/* Type imports ------------------------------------------------------------- */
import type { EtablissementConge } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const Chip = styled(ColoredSquareChip)`
  width: 100%;
  height: 100%;
  min-height: 37px;
  white-space: pre-line;
`

const DeleteButton = styled(Button)`
  width: 30px;
  min-width: 30px !important;
  height: 100%;
`

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px 250px 30px;
  gap: 10px;
  padding: 10px;
  padding-right: 15px;
  min-height: 60px;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: flex;
    flex-direction: column;
  }
`

/* Component declaration ---------------------------------------------------- */
interface VacationsCardProps {
  vacation: EtablissementConge;
}

const VacationsCard: React.FC<VacationsCardProps> = ({ vacation }) => {
  const isReadOnly = useIsReadOnly()

  const [
    deleteVacation,
  ] = useDeleteVacationMutation()

  const vacationEnded = useMemo(() => {
    // verify the vacation state
    const dateDebut = vacation.dateDebut ? new Date(vacation.dateDebut) : null
    const dateFin = vacation.dateFin? new Date(vacation.dateFin) : null
    const now = new Date()
    if (!dateDebut || !dateFin || isReadOnly) {
      return ''
    }
    if (dateFin < now) {
      return true
    }
    if (dateFin > now) {
      return false
    }
  }, [ vacation ])

  const onDeleteClick = () => {
    deleteVacation(vacation.id)
  }

  return (
    <Card>
      <CardContent>
        {`Congés du ${DateUtils.APIStrToLocalDateString(vacation.dateDebut)} au ${DateUtils.APIStrToLocalDateString(vacation.dateFin)}`}
        <Chip color={vacationEnded ? 'green' : 'orange'}>
          {vacationEnded ? 'Passée': 'À venir'}
        </Chip>
        {
          !vacationEnded && (
            <>
              {
                !vacationEnded ?
                  <VacationsCardButton
                    vacation={vacation}
                  /> :
                  <div />
              }
              <DeleteButton
                onClick={onDeleteClick}
                variant="outlined"
              >
                <Delete />
              </DeleteButton>
            </>
          )
        }
      </CardContent>
    </Card>
  )
}

export default VacationsCard
